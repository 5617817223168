
const jobData = {
    "digital-marketer": {
      "title": "Digital Marketer",
      "type": "REMOTE",
      "description": ["We are developing our Go-to-market. This job is for those who love championing customers and building brands from scratch.", "Bank transactions are a treasure trove of up-to-date information, yet it remains under-utilised because it is tedious to extract every bit of information from lengthy and text-heavy abbreviations and numbers. At Inkredo, our mission is to empower innovators to determine financial health in the easiest and most efficient manner. We have developed simple APIs for developers to sharpen their risk models without depending on various outdated documents.", "Our focus is on helping customers confident in credit risk mitigation. Our relationships with financial institutions are a key part of our product, as is collaborating with a variety of stakeholders to creatively solve the problem of determining up-to-date credit risk in financial services.", "We're looking for someone who has hunger to develop and execute go-to-marketing strategies. We are a product of experimentations, hence appreciate failures. We are looking for someone who is very curious and can ask stupid questions at ease. You will experience brand building and understand how to shape a developer brand through content that drive authentic engagement."],
      "responsibilities": ["Excellent communicator and writing skills; business writing skills - Becoming an expert in our products and product roadmap to clearly communicate them to customers and partners", "Storytelling - work with the team to develop narratives for our core audience including developers and finance professionals", "Audience Development and Insights: Understand audience, audience insights and audience needs: Determine how to reach and engage our target audience to breakthrough with key programs and messages", "Analyze large data sets to understand our growth marketing funnel and identify opportunities for expansion", "Working closely with the Product team to understand and influence roadmap"],
      "requirements": ["Exceptional writing and editing skills with extraordinary attention to detail", "A sensitivity for branding and how each interaction impacts customer perception", "Hands-on management and optimisation of customer acquisition channels: SEO, SEM, Social / Display ads; is a big plus", "Having worked on full-funnel growth analytics including A/B testing, channel effectiveness, and LTV/CAC analysis", "Ability to communicate effectively and work collaboratively with technical teams. Experience is not a bar at Inkredo"]
    },
    "backend-developer": {
      "title": "Backend Developer",
      "type": "REMOTE",
      "description": [
        "Micro-lenders use Inkredo hundreds of times daily. They use it for identifying financially-healthy borrowers in India and Thailand. Inkredo is building a data intelligence product to get lenders closer to their dream of 100% collections. Inkredo is a cloud-based SaaS solution that determines the financial health of an entity. It analyses banking transactions, invoices, and utility bills for credit assessment.",
        "You will learn how systems work and not just remain limited to technology. It starts from talking to customers, understanding their problems, designing solutions, understanding the architecture and delivering on the promise."
      ],
      "responsibilities": [
        "Good written and verbal communication. Our preferred language of communication is English. Just like us, you will also talk to customers every week. You'll do well here if you have read “How to Influence People and Win Friends” by Dale Carnegie.",
        "Expect shipping new features (50%), fixing bugs (20%) and making product improvements (30%) as routine.",
      ],
      "requirements": [
        "You've working knowledge of NodeJS, ExpressJS, and preferably NestJS.",
        "You will be building a new product from scratch to deepen customer relationships and increase the revenue for our customers.",
        "Taking frequent feedback and quickly iterating will be a regular.",
      ],
    },
};

export default jobData;