import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  },
  contentStyle: {
    marginTop: '1%',
    marginBottom: '1%',
  }
});

const ServiceLevelAgreement: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">SERVICE LEVEL AGREEMENT (SLA)</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          Inkredo is our lifeline and we understand it’s where you take decisions for risk mitigation. That’s why we guarantee 99.99% monthly uptime to teams on Inkredo.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">WHAT IS “UPTIME”?</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          “Uptime” refers to the period of time when Inkredo is up and running.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">HOW DO I FIND INKREDO'S UPTIME?</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          We'll make our uptime public <a href="https://status.inkredo.in" target="_blank" rel="noreferrer">here</a>. It's a work-in-progress and we'll update you in coming days so that you've complete transparency of how Inkredo works.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">WHAT HAPPENS IF UPTIME IS BELOW 99.99%?</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          What happens in case uptime is less than 99.99%, i.e. a maximum downtime of 8 seconds per day. Stuff happens because we're not entirely in control of everything, there are dependencies. In case we've got a downtime of more than 5 minutes on any day, we'll provide 10x credit for a full hour that is equal to the hourly rate the client pays us for the use of Inkredo in the next billing cycle. e.g. for monthly billing of Rs.8,999.00, any downtime exceeding 5 minutes during the month, we'll credit Rs.450.00 to the client in the next billing cycle. That is the full limit of our liability under the SLA. We've got you covered.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">HOW DO WE TRACK THAT UPTIME?</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          We track uptime by monitoring and measuring the server side error rate to look at results from ping tests, web server tests, TCP port tests, and website tests. Any changes to Inkredo's uptime are immediately shown on our status site.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">WILL THIS CHANGE IN THE FUTURE?</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          Inkredo may update this SLA once in a blue moon — we’ll notify you about significant changes by emailing the account owner or by placing a prominent notice on our site.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">If you still have questions, please get in touch with  <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a> and he’ll be happy to answer them!</Typography>
      </div>
    </div>
  );
}

export default ServiceLevelAgreement;