import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import moment from 'moment';
import { Link } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';


const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '5%',
  },
  headerStyle: {
    fontSize: '0.8rem',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '15%',
    marginRight: '15%',
    marginBottom: '4%',
  },
  paragraphStyle: {
    marginBottom: '4%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    padding: '5px',
    borderRadius: '5px',
  },
  authorName: {
    fontWeight: 'bold',
  },
  loader: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5%',
    marginTop: '5%',
  },
  linkStyle: {
    color: '#2A59FF',
    textDecoration: 'none',
  },
  margin: {
      marginTop: "4%",
  },
  cardStyle: {
      height: '100%',
  },
});

const ProductUpdates: React.FC = () => {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const response = await axios.get("https://blog.inkredo.in/ghost/api/v3/content/posts/?key=973660592c1c2fd7d52a6bd054&filter=tag%3AproductUpdate&include=tags");
    setPosts(response.data.posts)
  };

  const formatTag = (tag: string) => {
    return _.startCase(_.camelCase(tag)).toUpperCase();
};

  const classes = useStyles();

  if (posts.length === 0) {
    return <div className={classes.loader}><CircularProgress /></div>
}

  return (
    <div className={classes.containerStyle}>
      <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">What's New</Typography>
      <Grid style={{marginBottom: '7%'}} container spacing={8} >
      {posts.map((item) => (
        <Grid item xs={12} md={6} key={item.id}>
          <Card className={classes.cardStyle}>
            <CardContent>
                <Grid container>
                    <Grid item xs={6}><Typography className={classes.headerStyle} variant="body2" color="textSecondary" component="p">{moment(item.published_at).format("Do MMMM YYYY")}</Typography></Grid>
                    <Grid item xs={6}><Typography className={classes.headerStyle} variant="body2" color="textSecondary" align="right" component="p"><span className={classes.boxStyle}>{formatTag(item.tags[1].name)}</span></Typography></Grid>
                </Grid>
                <Typography className={classes.margin} gutterBottom variant="h5" component="h2">{item.title}</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{item.excerpt}</Typography>
                <Typography className={classes.margin} color="primary"><Link className={classes.linkStyle} to={`/product-updates/${item.slug}`}>Read More <i className="fas fa-long-arrow-alt-right"></i></Link></Typography>
             </CardContent>
           </Card>
        </Grid>
      ))}
      </Grid>
     </div>
  );
};

export default ProductUpdates;