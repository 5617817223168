import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import jobData from '../data/jobData';
import { RouteComponentProps } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle2: {
    fontSize: '1rem',
    marginBottom: '5%',
  },
  subHeadingStyle1: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '2%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '15%',
    marginRight: '15%',
  },
  paragraphStyle: {
    marginBottom: '2%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '2%',
    paddingTop: '5%',
    paddingBottom: '6%',
  },
  spanStyle: {
    color: '#2A59FF',
    textDecoration: 'none',
  },
  divStyle: {
    marginBottom: '6%',
  },
  formTextStyle: {
    fontSize: '0.8rem',
    marginBottom: '3%',
    padding: '1%',
  },
  buttonStyle: {
    padding: '1%'
  },
  browseTextStyle: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginLeft: '7px',
    marginTop: '0.5%',
  },
  browseContainerStyle: {
      margin: '1%',
  }
});

interface Props extends RouteComponentProps<{ slug: string }> {}

interface Role {
    title?: string;
    type?: string;
    description?: string[];
    responsibilities?: string[];
    requirements?: string[];
}

interface IState {
  name: string
  email: string
  organization: string
  roles: string
  resume?: File
  github?: string
  coverLetter?: File 
}

interface APIResponse {
  success?: Boolean
  message?: string
}

const Job: React.FC<Props> = ({ match }) => {

    const [details, setDetails] = useState<Role>({});
    const [APIResult, setAPIResult] = useState<APIResponse>({});
    const [formState, setFormState] = useState<IState>({
      name: "",
      email: "",
      organization: "",
      roles: "",
    })

    const classes = useStyles();
    const role = match.params.slug;

    function setInput(key: string, value: string | File ) {
      setFormState({ ...formState, [key]: value })
    } 

    let formData = new FormData();
    formData.append("roles", details.title);

    const submitHandler = async(e: React.FormEvent<HTMLFormElement>) => {

      e.preventDefault();
      formData.append("name", formState.name);
      formData.append("email", formState.email);
      formData.append("organization", formState.organization);
      formData.append("roles", formState.roles);
      formData.append("github", formState.github);
      formData.append("resume", formState.resume);
      formData.append("coverLetter", formState.coverLetter);

      let requestOptions: RequestInit = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      };
      
      fetch("https://inkredo.in/api/careers/tech", requestOptions)
        .then(response => response.text())
        .then(result => setAPIResult(JSON.parse(result)))
        .catch(error => console.log('error', error));

     }
    
    useEffect(() => {
        for (const [key, value] of Object.entries(jobData)) {
            if (key === role) {
                setDetails(value)
            }
        }
    }, [role])

    let display;
    if (details.description) {
      display = (
        <div>
            <div>
                <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">{details.title}</Typography>
                <Typography className={classes.subHeadingStyle2} align="center" variant="caption" component="p">{details.type}</Typography>
            </div>
            <div className={classes.divStyle}>
                <Typography className={classes.subHeadingStyle1} align="left" variant="subtitle1" component="p">Job description:</Typography>
                {details.description?.map((para) => (
                    <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="p">{para}</Typography>
                ))}
            </div>
            <div className={classes.divStyle}>
                <Typography className={classes.subHeadingStyle1} align="left" variant="subtitle1" component="p">Responsibilities:</Typography>
                {details.responsibilities?.map((para) => (
                    <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="li">{para}</Typography>
                ))}
            </div>
            <div className={classes.divStyle}>
                <Typography className={classes.subHeadingStyle1} align="left" variant="subtitle1" component="p">Requirements:</Typography>
                {details.requirements?.map((para) => (
                    <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="li">{para}</Typography>
                ))}
            </div>
          </div>
      )
    } else {
      display = (
        <div>
            <div>
                <Typography className={classes.subHeadingStyle2} align="center" variant="caption" component="p">We are still defining this role.</Typography>
            </div>
          </div>
      )
    }

    let formSection;
    if (APIResult.success) {
      formSection = (
        <div className={classes.boxStyle}>
          <Typography className={classes.subHeadingStyle1} align="left" variant="subtitle1" component="p">Thank You</Typography>
          <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="p">Dear Candidate,</Typography>
          <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="p">We appreciate your interest in Inkredo. If you are selected for the next round, you can expect an email or a phone call from our team in maximum five working days. We are grateful for your time invested in writing this application. All the best in your job hunt.</Typography>
          <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="p">Regards,</Typography>
          <Typography key={Math.random()} className={classes.paragraphStyle} align="left" variant="body1" component="p">Team Inkredo</Typography>
        </div>
      )
    } else {
      formSection = (
        <form className={classes.boxStyle} onSubmit={(e) => submitHandler(e)}>
              <TextField
                      id="name"
                      label="Full Name"
                      style={{ margin: 8 }}
                      fullWidth
                      onChange={(e) => setInput("name", e.target.value)}
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                        }}
                />
                <TextField
                      id="email"
                      label="Email"
                      onChange={(e) => setInput("email", e.target.value)}
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                        }}
                  />
                  <TextField
                      id="organisation"
                      label="Current organisation"
                      style={{ margin: 8 }}
                      fullWidth
                      onChange={(e) => setInput("organization", e.target.value)}
                      margin="normal"
                      helperText="(In case you are still studying, mention the name of college. In case you are already out of a job, please mention your last employer)"
                      InputLabelProps={{
                          shrink: true,
                        }}
                  />
                  <TextField
                      id="portfolio"
                      label="Portfolio/Github profile"
                      onChange={(e) => setInput("github", e.target.value)}
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                        }}
                  />
              <Typography className={classes.browseTextStyle} align="left" variant="body1" component="p">Add your resume</Typography>
              <input className={classes.buttonStyle} type="file" name="resume" onChange={(e) => setInput("resume", e.target.files[0])}/>
              <br></br>
              <br></br>
              <Typography className={classes.browseTextStyle} align="left" variant="body1" component="p">
                Tell us about a project that you have developed (proof of work); what were the challenges; what was your responsibility and how did other team members contribute, if any? And how did you measure its success? If otherwise, tell us about the metrics that indicated its failure and learnings from it. (At least 300 words)
              </Typography>
              <input className={classes.buttonStyle} type="file" name="coverLetter" onChange={(e) => setInput("coverLetter", e.target.files[0])}/>
              <br></br>
              <br></br>
              <Typography className={classes.formTextStyle} align="left" variant="body1" component="p">We recommend you to spend two minutes reading this <a className={classes.spanStyle} target="_blank" rel="noreferrer" href="https://medium.com/zodhana/why-are-we-looking-for-wordsmiths-707839a9e761">blog post</a>. Only 0.6% of all applicants have read this blog.</Typography>
              <input className="formSubmit" type="submit"/>
          </form>
      )
    }

    return (
        <div className={classes.containerStyle}>
          {display}
          {formSection}
          <Typography className={classes.subHeadingStyle2} align="center" variant="caption" component="p">If you are as excited as we are, explore more about it <a href="https://www.notion.so/Inkredo-Backend-Internship-43a7d584633d482780b11dee6f7ee87e">here.</a></Typography>
        </div>
    );
  }
  
  export default Job;