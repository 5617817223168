import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  }
});

const TermsOfService: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Terms of Service</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">
          By using the Inkredo (“Service”), you are agreeing to be bound by the following terms and conditions (“Terms of Service”).
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">
          Zodhana Mobile Solutions Pvt Ltd (“Company”) reserves the right to update and change these Terms of Service without notice.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">
          Violation of any of the terms below may result in the termination of your account.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">ACCOUNT TERMS</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You are responsible for all content posted and activity that occurs under your account (even when content is posted by others who have their own logins under your account).
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws).
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          We permit a single login shared by multiple people. In future, we'll allow you to create separate logins for as many people as you’d like. As and when we do, the account owner will receive an update via email for the same
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">API TERMS</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          Customers may access their Service data via the Application Program Interface (“API”). Any use of the API, including use of the API through a third-party product that accesses the Service, is bound by the terms of this agreement plus the following specific terms:
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          You expressly understand and agree that the Company shall not be liable for any damages or losses resulting from your use of the API or third-party products that access data via the API.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          Abuse or excessively frequent requests to the Service via the API may result in the temporary or permanent suspension of your account’s access to the API. The Company, in its sole discretion, will determine abuse or excessive usage of the API. The Company will make a reasonable attempt via email to warn the account owner prior to suspension.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">PAYMENT, REFUNDS, UPGRADING AND DOWNGRADING TERMS</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          The Service is offered with a free trial for 10 days. If you need more than 10 days, you will only be able to continue using the Service by paying in advance for additional usage. If you fail to pay for additional usage, your account will be frozen and inaccessible until payment is made.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          For any upgrade or downgrade in plan level, will result in the new rate being charged at the next billing cycle. There will be no prorating for downgrades in between billing cycles.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          Downgrading your Service may cause the loss of features or capacity of your account. The Company does not accept any liability for such loss.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties, excluding only United States (federal or state) taxes. Where required, The Company will collect those taxes on behalf of taxing authority and remit those taxes to taxing authorities.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          Refunds are processed according to our <Link className={classes.linkStyle} to="/fair-refund-policy">Fair Refund Policy</Link>.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">For related queries, please email at <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a></Typography>
      </div>
    </div>
  );
}

export default TermsOfService;