import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2A59FF',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  });

  export default theme;