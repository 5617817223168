export const blueBase = '#546ccf';
export const whiteBase = '#ffffff';
export const background = '#F7F8FC';
export const orange = '#e39f48';
export const bgBlue2 = 'rgba(42,89,255,0.5)';

export const primaryBlue = '#2A59FF';
export const secondaryBlue = '#354FBB';
export const darkBlue = '#001973';
export const lightBlue = '#AEC5FF';
export const blue = '#3F6AF2';