import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  },
  contentStyle: {
    marginTop: '1%',
    marginBottom: '1%',
  }
});

const SecurityOverview: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Security Overview</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          Security isn’t just about technology, it’s about trust. We’re working hard to earn the trust of over companies we work with, irrespective of its geographic location. We’ll continue to work hard every day to maintain that trust. Longevity and stability is core to our mission at Inkredo.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">LOCATION AND SITE OF DATA</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          This Site is operated in the India. If you are located in the European Union or elsewhere outside of India, please be aware that any information you provide to us will be transferred to the India. By using our Site, participating in any of our services and/or providing us with your information, you consent to this transfer.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">WE PROTECT YOUR DATA.</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          All data is written to the disk instantly and backed up daily in Mumbai, India. Files that our customers upload are stored on servers that use modern techniques to remove bottlenecks and points of failure.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">YOUR DATA IS SENT USING HTTPS.</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          Whenever your data is in transit between you and us, everything is encrypted, and sent using HTTPS. Any files which you upload to us are stored and are encrypted at rest.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">WE PROTECT YOUR BILLING INFORMATION.</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          All credit card transactions are processed using secure encryption—the same level of encryption used by leading banks. Card information is transmitted, stored, and processed securely on a PCI-Compliant network.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">Have you noticed abuse, misuse, an exploit, or experienced an incident with your account? Please email  <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a> for details on how to securely submit a report.</Typography>
      </div>
    </div>
  );
}

export default SecurityOverview;