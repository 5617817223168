import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  },
  contentStyle: {
    marginTop: '1%',
    marginBottom: '1%',
  }
});

const PrivacyPolicy: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Privacy Policy</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          The privacy of your data — and it is your data, not ours! — is a big deal to us. We’ll only ever access your account to help you with a problem or squash a software bug. We’ll never open any uploaded files unless you ask us to. We log all access to all accounts by IP address, so we can always verify that no unauthorised access has happened for as long as the logs are kept.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">IDENTITY AND ACCESS</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          When you sign up for Inkredo, we ask for your name, company name, and email address. That’s just so you can personalise your new account, and we can send you invoices, updates, or other essential information. We’ll never sell your personal info to third parties, and we won’t use your name or company in marketing statements without your permission, either. When you write Inkredo with a question or to ask for help, we’ll keep that correspondence, and the email address, for future reference. When you browse our marketing pages, we’ll track that for statistical purposes (like conversion rates and to test new designs). We also store any information you volunteer, like surveys, for as long as it makes sense.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          The only times we’ll ever share your info:
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          To provide products or services you’ve requested, with your permission.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          To investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our <Link className={classes.linkStyle} to="/terms-of-service">Terms of Service</Link>, or as otherwise required by law.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If Inkredo is acquired by or merged with another company — we don’t plan on that, but if it happens — we’ll notify you well before any info about you is transferred and becomes subject to a different privacy policy.
        </Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          You may have heard about the General Data Protection Regulation (“GDPR”) in Europe. GDPR gives people under its protection certain rights with respect to their personal information collected by us on the Site. Accordingly, Inkredo recognises and will comply with GDPR and those rights, except as limited by applicable law. The rights under GDPR include:
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right of Access.</strong> This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Correction.</strong> This is your right to request correction of your personal information.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Erasure.</strong> This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession (also known as the “Right to be forgotten”). However, if applicable law requires us to comply with your request to delete your information, fulfilment of your request may prevent you from using Inkredo services and may result in closing your account.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Complain.</strong> You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Object.</strong> This is your right, in certain situations, to object to how or why your personal information is processed.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Portability.</strong> This is your right to receive the personal information we have about you and the right to transmit it to another party.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to not be subject to Automated Decision-Making.</strong> This is your right to object and prevent any decision that could have a legal, or similarly significant, effect on you from being made solely based on automated processes. This right is limited, however, if the decision is necessary for performance of any contract between you and us, is allowed by applicable European law, or is based on your explicit consent.
        </Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          Many of these rights can be exercised by signing in and directly updating your account information. If you have questions about exercising these rights or need assistance, please contact us at <a href="mailto:devs@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>devs@inkredo.in</a>
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">LAW ENFORCEMENT</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          Inkredo won’t hand your data over to law enforcement unless a court order says we have to. We flat-out reject requests from local and federal law enforcement when they seek data without a court order. And unless we’re legally prevented from it, we’ll always inform you when such requests are made.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">For related queries, please email at <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a></Typography>
      </div>
    </div>
  );
}

export default PrivacyPolicy;