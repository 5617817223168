import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import topAutoDebits from '../images/topAutoDebits.svg';
import checkingSummary from '../images/checkingSummary.svg';
import defaultEvents from '../images/defaultEvents.svg';
import modelWithAPI from '../images/modelWithAPI.svg';
import timeYourRepayments from '../images/timeYourRepayments.svg';
import verifyIncome from '../images/verifyIncome.svg';
import Bandhan from '../images/Bandhan.svg';
import BoB from '../images/BoB.svg';
import DBS from '../images/DBS.svg';
import eko from '../images/eko.svg';
import HDFC from '../images/HDFC.svg';
import ICICI from '../images/ICICI.svg';
import IDFC from '../images/IDFC.svg';
import paisagrowth from '../images/paisagrowth.svg';
import pichain from '../images/pichain.png';
import nemo from '../images/nemo.svg';
import badabro from '../images/badabro.png';
import Paytm from '../images/Paytm.svg';
import PNB from '../images/PNB.svg';
import pumpumpum from '../images/pumpumpum.svg';
import RBL from '../images/RBL.svg';
import SBI from '../images/SBI.svg';
//import ReactPlayer from 'react-player';
import {Paper} from "@material-ui/core";
import {blue, blueBase, whiteBase} from "../styles/colors";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '2%',
    marginTop: '5%',
    '@media (max-width: 768px)': {
      fontSize: '2.3rem',
    },
  },
  headerTextStyle: {
    fontSize: '1.2rem',
    marginBottom: '6%',
  },
  subHeadingStyle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '4%',
    marginTop: '4%',
    width: '100%',
    '@media (max-width: 768px)': {
      fontSize: '2.1rem',
      width: '95%',
    },
  },
  subHeadingStyle2: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: '2%',
    '@media (max-width: 768px)': {
      fontSize: '1.4rem',
    },
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    marginBottom: '2%',
    '@media (max-width: 768px)': {
       fontSize:'1.1rem'
     }
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  spanStyle: {
    fontSize: '1.2rem',
    color: '#2A59FF',
  },
  signUpHeaderStyle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginBottom: '2%',
  },
  signUpBoxStyle: {
    marginBottom: '5%',
    marginTop: '3%',
    width:'80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  divStyle: {
    marginBottom: '5%',
  },
  iconContainerStyle: {
    display: 'flex',
    padding: '1%',
    justifyContent: 'center',
  },
  iconItemStyle: {
    textDecoration: 'line-through',
    color: '#4A5568',
  },
  gridContainerStyle: {
    marginBottom: '10%',
  },
  imageStyle: {
    display: 'flex',
    width: '90%',
  },
  logoTextStyle: {
    alignSelf: 'center',
    color: '#4A5568',
    fontSize: '1rem',
    marginBottom: '2%',
  },
  paperStyle: {
      backgroundColor: blue,
      color: whiteBase,
      padding: '1%',
      marginBottom: '2%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '@media (max-width: 768px)': {
        display: 'none',
      }
  },
  buttonStyle: {
      textTransform: 'none',
      backgroundColor: whiteBase,
      color: blueBase,
      marginRight: '5%',
      fontSize: '0.75rem',
  },
  bannerTextStyle: {
      marginRight: '2%',
      fontSize: '0.875rem',
  },
  demoButtonStyle: {
    marginTop: '5%',
  },
});

const Home: React.FC = () => {

  const classes = useStyles();

    const login = () => {
        window.open(`${process.env.REACT_APP_WEBAPP}/auth/login`, '_blank');
    };

  const signUpBox = (hideLabel: boolean) => (
        <div className={classes.signUpBoxStyle}>
            {hideLabel ? null : <Typography className={classes.signUpHeaderStyle} align="center" variant="h3" component="h3">Try now. <span className={classes.spanStyle}>It's free!</span></Typography>}
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} md={5} lg={3}>
                <Button onClick={() => window.location.href=process.env.REACT_APP_DEMO_URL} fullWidth={true} size="large" variant="contained" color="primary">TRY NOW</Button>
              </Grid>
            </Grid>
          </div>
  );

  const tryNowBox = (
    <div className={classes.signUpBoxStyle}>
        <Typography className={classes.signUpHeaderStyle} align="center" variant="h3" component="h3"><span className={classes.spanStyle}>First seven days are on us!</span> No sign up required</Typography>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} md={5} lg={3}>
            <Button onClick={() => window.location.href=process.env.REACT_APP_DEMO_URL} className={classes.demoButtonStyle} fullWidth={true} size="large" variant="contained" color="primary">TRY NOW</Button>
          </Grid>
        </Grid>
      </div>
);

  const oldBanner = (
      <Paper className={classes.paperStyle} elevation={2}>
        <Typography className={classes.bannerTextStyle}>
            Old dashboard users can login here
        </Typography>
        <Button
            className={classes.buttonStyle}
            variant="contained"
            size="small"
            disableElevation
            onClick={login}
        >
            Log In
        </Button>
      </Paper>
  );

  return (
    <div className={classes.containerStyle}>
      {oldBanner}
      <div className={classes.boxStyle}>
        <div>
          <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Find financially healthy entities who repay on time</Typography>
          <Typography className={classes.headerTextStyle} align="center" variant="h2" component="h2">Determine income, loans, and capability from bank statements in seconds</Typography>
        </div>
        {tryNowBox}
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}><Typography className={classes.logoTextStyle} align="center" variant="body1" component="p">Trusted by fast growing startups</Typography></Grid>
            <Grid item container spacing={3} justify="center" alignItems="center">
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={pichain} alt="pichain logo"/></Grid>
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={badabro} alt="badabro logo"/></Grid>
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={nemo} alt="nemo logo"/></Grid>
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={paisagrowth} alt="paisagrowth logo"/></Grid>
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={eko} alt="eko logo"/></Grid>
                <Grid item xs={4} md={2}><img className={classes.imageStyle} src={pumpumpum} alt="pumpumpum logo"/></Grid>
          </Grid>
        </Grid>
      </div>

      <div className={classes.divStyle}>
        <Grid container spacing={3} justify="center" alignItems="flex-start">
{/*          <Grid item xs={12}>
            <ReactPlayer className="video" url='https://www.youtube.com/watch?v=9IqF6t7B9fc&feature=youtu.be'/>
          </Grid>*/}
          <Typography className={classes.subHeadingStyle} align="center" variant="h2" component="h2">Find credit worthy entities in two steps</Typography>
          <Grid item xs={12} md={6}>
            <Typography className={classes.spanStyle} align="left" variant="caption" component="p">STEP 1</Typography>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Upload bank statement from 60+ banks</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Upload multiple statements from different banks in one step. No page limit ever.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.spanStyle} align="left" variant="caption" component="p">STEP 2</Typography>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Know your customer’s financial health in seconds</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Download results in CSV or JSON response</Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.boxStyle}>
        <Typography className={classes.subHeadingStyle} align="center" variant="h2" component="h2">We care for your money from day one</Typography>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.iconContainerStyle}>
            <Typography className={classes.iconItemStyle} variant="body1" component="p">No lock-in contract terms</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.iconContainerStyle}>
            <Typography className={classes.iconItemStyle} variant="body1" component="p">No hidden charges</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.iconContainerStyle}>
            <Typography className={classes.iconItemStyle} variant="body1" component="p">No hefty upfront or integration fee</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.iconContainerStyle}>
            <Typography className={classes.iconItemStyle} variant="body1" component="p">No annual maintenance contract/ fee</Typography>
          </Grid>
        </Grid>
        {signUpBox(true)}
      </div>

      <div>
        <Typography className={classes.subHeadingStyle} align="center" variant="h2" component="h2">Effortlessly find defaults, hidden loans and income</Typography>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Spot manipulations easily</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Easily identify anomalies like missing, erroneous or fraudulent transactions when your customers provide statements, using our intelligent models. The models check summaries and verify transactions that do not add up in their statements.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={checkingSummary} alt="Checking summary" />
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={topAutoDebits} alt="Bar chart showing top auto debits" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Never miss a responsible borrower</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Cluster all kinds of investing or lending activities based on transactions like top auto debits in virtually no time.</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Time your payments</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Determine seasonal cash flows based on average balance and net cash flow over a period of time</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={timeYourRepayments} alt="A plot with date on x-axis and repayments on y-axis" />
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={verifyIncome} alt="Data analysis for income verification" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Verify sources of income</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Say goodbye to manual processes and documentation. Say hello to instant income verification. Identify income and expenditures with our categorization of recurring credits and debits.</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">Never miss a default</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Visualize all events at a glance, where your customer has defaulted, be it a cheque bounce or an auto-debit or standing instruction. Know not just the default events, but also find details of amount owed and number of times, your customer has defaulted.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={defaultEvents} alt="A table showing how many times a user has defaulted" />
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainerStyle} spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <img className={classes.imageStyle} src={modelWithAPI} alt="Code" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.subHeadingStyle2} align="left" variant="h4" component="h4">APIs made for your dashboard</Typography>
            <Typography className={classes.paragraphStyle} align="left" variant="body1" component="p">Optimise  experiences using easy to integrate APIs</Typography>
          </Grid>
        </Grid>
        
      </div>
      <div className={classes.boxStyle}>
          <div className={classes.boxStyle}>
            <Typography className={classes.logoTextStyle} align="center" variant="body1" component="p">We support bank statements from 60+ banks.</Typography>
            <Grid container spacing={5} justify="center" alignItems="center">
                  <Grid item xs={2}><img className={classes.imageStyle} src={ICICI} alt="ICICI logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={HDFC} alt="HDFC logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={SBI} alt="SBI logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={IDFC} alt="IDFC logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={Paytm} alt="Paytm logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={RBL} alt="RBL logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={Bandhan} alt="Bandhan logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={DBS} alt="DBS logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={PNB} alt="PNB logo"/></Grid>
                  <Grid item xs={2}><img className={classes.imageStyle} src={BoB} alt="BoB logo"/></Grid>
            </Grid>
          </div>
        {signUpBox(false)}
      </div>
    </div>
  );
};

export default Home;