import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  tabStyle: {
    color: '#060F2E',
    fontSize: '1rem',
  },
});

const TopNavbar: React.FC = () => {

  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if(newValue !== 3)
        setValue(newValue);
  };

  const goToBlog = () => {
    window.open('https://blog.inkredo.in', '_blank');
  };

  let location = useLocation();

  useEffect(() => {
    if (location.pathname === "/careers") {setValue(2)}
    else if (location.pathname === "/careers/") {setValue(2)}
    /*else if (location.pathname === "/blog") {setValue(3)}
    else if (location.pathname === "/blog/") {setValue(3)}*/
    else if (location.pathname === "/security") {setValue(4)}
    else if (location.pathname === "/security/") {setValue(4)}
    else if (location.pathname === "/api/") {setValue(1)}
    else if (location.pathname === "/api") {setValue(1)}
    else {setValue(0)}
  }, [location.pathname]);

  return (
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >    
          <Tab value={1} className={classes.tabStyle} style={{minWidth:"10%"}} label="API" component={Link} to="/api"/>
          <Tab value={2} className={classes.tabStyle} style={{minWidth:"10%"}} label="CAREERS" component={Link} to="/careers"/>
          <Tab value={3} className={classes.tabStyle} style={{minWidth:"10%"}} label="BLOG" onClick={goToBlog} />
          <Tab value={4} className={classes.tabStyle} style={{minWidth:"10%"}} label="SECURITY" component={Link} to="/security" />
      </Tabs>
  );
};

export default TopNavbar;
