import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import {INKREDO_BLOG} from "../utils/constants";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '3%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '15%',
    marginRight: '15%',
  },
  paragraphStyle: {
    marginBottom: '2%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
});

const ErrorPage: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div className={classes.boxStyle}>
          <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">404 Page Not Found</Typography>
          <Typography
              className={classes.paragraphStyle}
              align="center"
              variant="body1"
              component="p"
          >
              We are sorry for not being able to find what you are looking for. Try our <Link to="/">homepage</Link> or <a href={INKREDO_BLOG} target="_blank" rel="noreferrer">blog</a> instead.
          </Typography>
      </div>
    </div>
  );
};

export default ErrorPage;