import React from "react";
//import "rapidoc";
import { RedocStandalone } from 'redoc';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    containerStyle: {
      marginTop: '4%',
      marginLeft: '3%',
      marginRight: '6.5%',
      marginBottom: '4%',
    },
    headingStyle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '3%',
      },
    emailStyle: {
      color: '#2A59FF',
      textDecoration: 'none',
      },
    paragraphStyle: {
      marginTop: '1%',
      marginBottom: '2%',
    },
  });

const APIDoc = () => {

    const classes = useStyles();

    return (
        <div className={classes.containerStyle}>
            <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">API Documentation</Typography>
{/*            <rapi-doc
                id="APIcomponent"
                style={{ height: "100%", width: "100%", backgroundColor: '#F2F5FE', padding: '2%'}}
                spec-url={process.env.REACT_APP_OPENAPI_URL}
                render-style="focused"
                primary-color='#2A59FF'
                header-color='#F2F5FE'
                show-header="false"
                allow-server-selection="false"
                show-info="true"
                allow-try="false"
                schema-style="table"
                allow-search="false"
                allow-advance-search="false"
                show-components="false"
                schema-description-expanded="true"
            />*/}
            <RedocStandalone
                specUrl={process.env.REACT_APP_OPENAPI_URL}
                options={{
                    nativeScrollbars: true,
                    expandResponses: '200',
                    schemaExpansionLevel: 4,
                    theme: { colors: { primary: { main: '#2A59FF' } } },
                }}
            />
            <Typography className={classes.paragraphStyle} align="center" variant="body1" component="p">If you encounter any problems with the API, please contact our developers at <a href="mailto:support@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>support@inkredo.in</a>.</Typography>
        </div>
  );
};

export default APIDoc;
