import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  },
  contentStyle: {
    marginTop: '1%',
    marginBottom: '1%',
  }
});

const FairRefundPolicy: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Fair Refund Policy</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is simple: If you’re ever unhappy with Inkredo, for any reason, just contact <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a> and we’ll take care of you.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">EXAMPLES OF FULL REFUNDS WE’D GRANT.</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If you were just charged for your next month of Inkredo, but you meant to cancel, we’re happy to refund that extra charge.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If you forgot to cancel Inkredo a couple months ago, and you haven’t used Inkredo since then, we’ll give you a full refund for a few back months. No problem.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If you tried Inkredo for a couple months, and you just weren’t happy with it, you can have your money back.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">EXAMPLES OF PARTIAL REFUNDS OR CREDITS WE’D GRANT.</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If you forgot to cancel Inkredo a year ago, and there’s been activity on your account since then, we’ll review your account usage and figure out a partial refund based on how many months you used it.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If you upgraded your account a few months ago to a higher plan, but you didn’t end up using the extra features, projects, or storage space, although you did keep using Inkredo in general, we’d consider applying a prorated credit towards future months.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If we had extended downtime (multiple hours in a day, or multiple days in a month) or you emailed tanmay@inkredo.in and it took multiple days to get back to you, we’d issue a partial credit to your account.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">CASE-BY-CASE BASIS</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          At the end of the day, nearly everything on the edges comes down to a case-by-case basis. Send <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a> a note, tell us what’s up, and we’ll work with you to make sure you’re happy.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">For related queries, please email at <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a></Typography>
      </div>
    </div>
  );
}

export default FairRefundPolicy;