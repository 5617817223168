import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '3%',
    marginBottom: '1%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  lastUpdatedStyle: {
    textDecoration: 'none',
    marginBottom: '3%',
    color: '#ABACAD',
  },
  contentStyle: {
    marginTop: '1%',
    marginBottom: '1%',
  }
});

const GDPR: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">GDPR</Typography>
        <Typography className={classes.lastUpdatedStyle} align="center" variant="body1" component="p">Last updated: 26 Feb 2022</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">
          GDPR gives people protection rights with respect to their personal information collected by us on the site. This policy applies to personal data from the European Union, the United Kingdom, and Switzerland which is collected, used, and retained by us in India.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">IDENTITY AND ACCESS</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          When you sign up for Inkredo, we ask for your name, company name, email address, company PAN and company registration. That’s just so you can personalize your new account, and we can send you invoices, updates, or other essential information. We’ll never sell your personal info to third parties, and we won’t use your name or company in marketing statements without your permission, either.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          You always have the right to access the personal information we store about you. And, if you wish to further limit our use of your personal information, please contact <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a>
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          We store your billing address to calculate any sales tax due in India, to detect fraudulent transactions, and to print on your invoices.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          When you write to Inkredo with a question or to ask for help, we’ll keep that correspondence, and the email address, for future reference. When you browse our marketing pages, we’ll track that for statistical purposes (like conversion rates and to test new designs). We also store any information you volunteer, like surveys, for as long as it makes sense.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          We log all access to all accounts by IP address, so we can always verify that no unauthorized access has happened for as long as the logs are kept.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          Inkredo does not share individual’s personal data with non-agent third parties. If this policy changes in the future, we will notify individuals and provide them with an opportunity to opt-out of having their data shared.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          The only times we’ll ever share your info:
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          To provide products or services you’ve requested, with your permission. Please see <strong>List of third-party services we use</strong>.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          To investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our <Link className={classes.linkStyle} to="/terms-of-service">Terms of Service</Link>, or as otherwise required by law.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          If Inkredo is acquired by or merged with another company—we don’t plan on that, but if it happens—we’ll notify you well before any info about you is transferred and becomes subject to a different privacy policy.
        </Typography>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          As part of the services we provide, and only to the extent necessary, we may use certain third party processors to process some or all of your personal information. For identification of these processors, and where they are located, please see our <strong>Subprocessor listing</strong>. We have signed appropriate data processing contracts that comply with GDPR with each processor.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">LIST OF THIRD PARTY SERVICES/ SUB-PROCESSOR LIST</Typography>
      </div>
      <div>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank" rel="noreferrer">AWS</a> - Cloud service provider
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://www.digitalocean.com/legal/gdpr/" target="_blank" rel="noreferrer">Digital Ocean</a> - Cloud service provider
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://cloud.google.com/privacy/gdpr" target="_blank" rel="noreferrer">Google Cloud</a> - Cloud service provider
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://docs.microsoft.com/en-us/compliance/regulatory/gdpr-dsr-Azure?view=o365-worldwide" target="_blank" rel="noreferrer">Microsoft Azure</a> - Cloud service provider
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noreferrer">Google Analytics</a> - Web analytics service
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://heap.io/blog/heaps-commitment-to-gdpr-and-data-privacy" target="_blank" rel="noreferrer">Heap Analytics</a> - Web analytics service
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://blog.sentry.io/2018/03/14/gdpr-sentry-and-you" target="_blank" rel="noreferrer">Sentry</a> - Error logging
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://www.zoho.com/gdpr.html" target="_blank" rel="noreferrer">Zoho Books</a> - Sales Tax Calculation
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://help.github.com/en/articles/github-privacy-statement" target="_blank" rel="noreferrer">Github</a> - Code repository
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://slack.com/gdpr" target="_blank" rel="noreferrer">Slack</a> - Product management and internal communication
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <a href="https://sendgrid.com/resource/general-data-protection-regulation-2/" target="_blank" rel="noreferrer">Sendgrid</a> - Transactional email service
        </Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">RIGHTS WITH RESPECT TO YOUR INFORMATION</Typography>
      </div>
      <div>
        <Typography className={classes.contentStyle} align="left" variant="body1" component="p">
          GDPR gives people under its protection certain rights with respect to their personal information collected by us on the Site. Accordingly, Inkredo recognizes and will comply with GDPR and those rights, except as limited by applicable law. The rights under GDPR include:
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right of Access.</strong> This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Correction.</strong> This is your right to request correction of your personal information.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Erasure.</strong> This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession (also known as the “Right to be forgotten”). However, if applicable law requires us to comply with your request to delete your information, fulfilment of your request may prevent you from using Inkredo services and may result in closing your account.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Complain.</strong> You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Object.</strong> This is your right, in certain situations, to object to how or why your personal information is processed.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to Portability.</strong> This is your right to receive the personal information we have about you and the right to transmit it to another party.
        </Typography>
        <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">
          <strong>Right to not be subject to Automated Decision-Making.</strong> This is your right to object and prevent any decision that could have a legal, or similarly significant, effect on you from being made solely based on automated processes. This right is limited, however, if the decision is necessary for performance of any contract between you and us, is allowed by applicable European law, or is based on your explicit consent.
        </Typography>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">Many of these rights can be exercised by signing in and directly updating your account information. If you have questions about exercising these rights or need assistance, please contact us at <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a>.</Typography>
      </div>
    </div>
  );
}

export default GDPR;