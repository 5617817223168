import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    headingStyle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '2%',
      },
    subHeadingStyle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '2%',
      },
    containerStyle: {
      marginTop: '4%',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '4%',
    },
    paragraphStyle: {
      marginBottom: '4%',
    },
    boxStyle: {
      backgroundColor: '#F2F5FE',
      marginBottom: '5%',
      padding: '3%',
    },
    authorName: {
      fontWeight: 'bold',
    },
    loader: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '5%',
      marginTop: '5%',
    }
  });

  const ProductUpdateDetails: React.FC = (props: any) => {

    const [post, setPost] = useState([]);

    const slug = props.match.params.slug;
  
    useEffect(() => {
      const fetchPost = async () => {
        const response = await axios.get(`https://blog.inkredo.in/ghost/api/v3/content/posts/slug/${slug}/?key=973660592c1c2fd7d52a6bd054`);
        setPost(response.data.posts);
      };
      fetchPost();
    }, [slug]);
  
    const classes = useStyles();

    if (post.length === 0) {
      return <div className={classes.loader}><CircularProgress /></div>
    }
  
    return (
      <div className={classes.containerStyle}>
        <div className={classes.boxStyle}>
            <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">{post[0].title}</Typography>
            <Typography variant="body2" color="textSecondary" component="p" align="center">Last modified on: {moment(post[0].updated_at).format("Do MMMM YYYY")}</Typography>
            <Typography className={classes.paragraphStyle} align="center" variant="body1" component="p">{post[0].excerpt}</Typography>
        </div>
        <Typography className="blogContent" dangerouslySetInnerHTML={{ __html: post[0].html}}></Typography>
       </div>
    );
  };
  
  export default ProductUpdateDetails;