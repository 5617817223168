import React from 'react';
import Header from './Header';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Careers from '../pages/Careers';
import Security from '../pages/Security';
import Footer from '../components/Footer';
import Job from '../pages/Job';
// import Blog from '../pages/Blog';
// import BlogPost from '../pages/BlogPost';
import ErrorPage from '../pages/ErrorPage';
import ProductUpdates from '../pages/ProductUpdates';
import ProductUpdateDetails from '../pages/ProductUpdateDetails';
import Legal from '../pages/Legal';
import APIDoc from '../pages/APIDoc';
import TermsOfService from "../pages/legal/TermsOfService";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import FairRefundPolicy from "../pages/legal/FairRefundPolicy";
import SecurityOverview from "../pages/legal/SecurityOverview";
import GDPR from "../pages/legal/GDPR";
import ServiceLevelAgreement from "../pages/legal/ServiceLevelAgreement";

const Router: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/careers" component={Careers}/>
          <Route exact path="/security" component={Security}/>
          <Route exact path="/api" component={APIDoc}/>
          <Route exact path="/legal" component={Legal}/>
          <Route exact path="/terms-of-service" component={TermsOfService}/>
          <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
          <Route exact path="/fair-refund-policy" component={FairRefundPolicy}/>
          <Route exact path="/security-overview" component={SecurityOverview}/>
          <Route exact path="/gdpr" component={GDPR}/>
          <Route exact path="/service-level-agreement" component={ServiceLevelAgreement}/>
          {/*<Route exact path="/blog" component={Blog}/>
          <Route exact path="/blog/:slug" component={BlogPost}/>*/}
          <Route exact path="/careers/:slug" component={Job}/>
          <Route exact path="/product-updates" component={ProductUpdates}/>
          <Route exact path="/product-updates/:slug" component={ProductUpdateDetails}/>
          <Route path="/" render={() => <ErrorPage />} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default Router;