import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  headingStyle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '3%',
  },
  subHeadingStyle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '2%',
  },
  containerStyle: {
    marginTop: '4%',
    marginLeft: '3%',
    marginRight: '3%',
  },
  paragraphStyle: {
    margin: '1.5%',
  },
  boxStyle: {
    backgroundColor: '#F2F5FE',
    marginBottom: '5%',
    padding: '3%',
  },
  emailStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
  },
  linkStyle: {
    textDecoration: 'none',
  },
});

const Legal: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div>
        <Typography className={classes.headingStyle} align="center" variant="h1" component="h1">Legal</Typography>
      </div>
      <div>
        <Typography className={classes.subHeadingStyle} align="left" variant="subtitle1" component="p">OUR POLICIES, TERMS, AND LEGAL STUFF.</Typography>
      </div>
      <div>
        <Typography align="left" variant="body1" component="p">The rough print and the fine print. We try to make all our policies as clear, fair, and readable as possible.</Typography>
        <Link className={classes.linkStyle} to="/terms-of-service">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">Terms of Service</Typography>
        </Link>
        <Link className={classes.linkStyle} to="/privacy-policy">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">Privacy Policy</Typography>
        </Link>
        <Link className={classes.linkStyle} to="/fair-refund-policy">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">Fair Refund Policy</Typography>
        </Link>
        <Link className={classes.linkStyle} to="/security-overview">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">Security Overview</Typography>
        </Link>
        <Link className={classes.linkStyle} to="/gdpr">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">GDPR</Typography>
        </Link>
        <Link className={classes.linkStyle} to="/service-level-agreement">
          <Typography className={classes.paragraphStyle} align="left" variant="body1" component="li">Service Level Agreement</Typography>
        </Link>
      </div>
      <div className={classes.boxStyle}>
        <Typography align="left" variant="body1" component="p">For related queries, please email at <a href="mailto:tanmay@inkredo.in" target="_blank" rel="noreferrer" className={classes.emailStyle}>tanmay@inkredo.in</a></Typography>
      </div>
    </div>
  );
}

export default Legal;